import React, { useState } from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout/layout"
import Meta from "../components/addon/meta"
import BtnLink from "../components/addon/Link"

const Academics = ({ data: { allNodeTemplatePages: { edges } } }) => {

    const rawData = edges[0]
    const metaTag = [];
    const data = rawData?.node?.relationships?.components;
    
    const bannerData = Array.isArray(data) && data.length > 0 && data[0];
    const bannerImg = typeof(bannerData?.relationships?.components?.[0]?.relationships?.bannerImage?.uri?.url) !== "undefined" ? bannerData?.relationships?.components?.[0]?.relationships?.bannerImage?.uri?.url : "/assets/images/patient_care/banner_patient_care.jpg"
    const mobileBannerImg = bannerData?.relationships?.components?.[0]?.relationships?.mobileBannerImage?.uri?.url || bannerImg;
    const bannerAlt = typeof(bannerData?.relationships?.components?.[0]?.bannerImage?.alt) !== "undefined" && bannerData?.relationships?.components?.[0]?.bannerImage?.alt.length > 0 ? bannerData?.relationships?.components?.[0]?.bannerImage?.alt : "Hinduja Hospital"
    const bannerTitle = typeof(bannerData?.relationships?.components?.[0]?.title) !== "undefined" && bannerData?.relationships?.components?.[0]?.title.length > 0 ? bannerData?.relationships?.components?.[0]?.title : "";
    const bannerSubtitle = typeof(bannerData?.relationships?.components?.[0]?.subtitle) !== "undefined" && bannerData?.relationships?.components?.[0]?.subtitle.length > 0 ? bannerData?.relationships?.components?.[0]?.subtitle : "";
    
    const contentData = data[1]?.relationships?.components[0]
    
    // Modal Data
    const allModalData = Array.isArray(data) && data.length > 4 && data?.[4]?.relationships?.components;
    
    return (
        <>
            <Layout>
                <Meta
                    files={{
                        js: [],
                        css: ["/assets/css/academics.css", "/assets/css/accessibility.css"],
                    }}
                    tags={metaTag}
                />
                <main className="innerpage pt-first-section">
                    <section className="inner_common_banner">
                        <picture className="banner_img">
                          <img src={mobileBannerImg} alt={bannerAlt} className="d-md-none"/>
                          <img src={bannerImg} alt={bannerAlt} className="d-md-block d-none"/>
                        </picture>
                        <div className="banner_content text_left text_dark">
                            <div className="container">
                                <h1>{bannerTitle}</h1>
                                <p>{bannerSubtitle}</p>
                            </div>
                        </div>
                    </section>
                    <section className="section_bg py-3 academicMainWrap">
                        <div className="academicCommonTab">
                            <div className="container">
                                {contentData && contentData?.field_title && contentData?.text?.processed 
                                ?  <div className="py-4">
                                    <h2>{contentData?.field_title || ''}</h2>
                                    {contentData?.text?.processed 
                                    ? <div dangerouslySetInnerHTML={{ __html: contentData?.text?.processed }} />
                                    : null}
                                </div>
                                : null}
                            </div>
                        </div>
                    </section>
                </main>
                {allModalData?.length 
                ? allModalData.map((modal, i) => {
                    return (
                        <div className="modal common_modal fade pay_modal modal-center academic-modal" key={modal?.field_modal_id} id={modal?.field_modal_id || ''} tabIndex="-1" role="dialog" aria-labelledby="basicModal" aria-hidden="true">
                            <div className="modal-dialog modal-dialog-centered modal-lg">
                                <div className="modal-content">
                                    <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                        <span aria-hidden="true">&times;</span>
                                    </button>
                                    {modal?.field_title &&
                                        <div className="modal-header">
                                            <h2>{modal?.field_title}</h2>
                                        </div>
                                    }
                                    <div className="modal-body">
                                        <div className="pay_terms_wrap">
                                            <div dangerouslySetInnerHTML={{ __html: modal?.field_description?.processed }} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )
                }) 
                : null}
            </Layout>
        </>
    )
}

export const query = graphql`
query academics {
  allNodeTemplatePages(filter: {path: {alias: {regex: "/academics$/"}}}) {
    edges {
      node {
        id
        title
        path {
          alias
        }
        metatag {
          attributes {
            content
            href
            name
            property
            rel
          }
        }
        relationships {
          components: field_component_type {
            __typename
            ...acaParagraphSection
          }
        }
      }
    }
  }
}

fragment acaParagraphSection on paragraph__section {
  id
  name: field_name
  relationships {
    components: field_components {
      __typename
      ...acaParagraphBanner
      ...acaParagraphTitleAndDescription
      ... on paragraph__title_and_description_components {
        id
        field_name
        field_title
        field_subtitle
        field_link {
          uri
          title
        }
        relationships {
          field_components {
            ...acaParagraphTitleAndDescription
            ...acaParagraphImageTitleDescLink
          }
        }
      }
      ... on paragraph__modal {
        id
        field_title
        field_modal_id
        field_description {
          processed
        }
      }
    }
  }
}

fragment acaParagraphBanner on paragraph__banner {
  id
  title: field_title
  subtitle: field_subtitle
  bannerImage: field_banner_image {
    alt
  }
  mobileBannerImage: field_mobile_banner {
    alt
  }
  bannerLink: field_banner_link {
    uri
    title
  }
  relationships {
    bannerImage: field_banner_image {
      id
      uri {
        value
        url
      }
    }
    mobileBannerImage: field_mobile_banner {
      id
      uri {
        url
        value
      }
    }
  }
}

fragment acaParagraphTitleAndDescription on paragraph__title_and_description {
  id
  field_title
  text: field_description {
    processed
  }
  field_cta_link {
    title
    uri
  }
  field_text {
    processed
  }
}

fragment acaParagraphImageTitleDescLink on paragraph__image_title_desc_link {
  id
  title: field_title
  title1: field_title_1
  description: field_description {
    processed
  }
  link: field_link {
    uri
    title
  }
  image: field_image {
    alt
  }
  relationships {
    image: field_image {
      id
      uri {
        value
        url
      }
    }
  }
}

`
export default Academics